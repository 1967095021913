import React, { useEffect, useRef } from 'react';
import { LAZYLAWN_APIS } from 'Adapters';
import { MapArea } from 'Components/Commons';
import Input from 'Components/Commons/InputField/Input';
import { grassOptions, installationOptions, playgroundTypes } from 'Constant';
import { isValidEmail, separateStreetAndRoute } from 'Utils';

const ManualEstimateForm = ({ values, setFieldValue }) => {
  const timeoutRef = useRef(null);
  const fbSearchTimeoutRef = useRef(null);

  const getContactDetails = async () => {
    if (!values.email || !isValidEmail(values.email)) return;
    const res = await LAZYLAWN_APIS.get.getKeapClientByEmail(values.email);
    if (res.data.data.contacts.length > 0) {
      const contactDetails = res?.data?.data?.contacts?.[res.data.data.contacts.length - 1];
      setFieldValue('lname', contactDetails?.family_name);
      setFieldValue('fname', contactDetails?.given_name);
      setFieldValue(
        'phone',
        contactDetails?.phone_numbers && contactDetails?.phone_numbers[0]?.number,
      );
      if (contactDetails?.addresses && contactDetails?.addresses.length > 0) {
        const address = contactDetails.addresses;
        const { street_number, route } = separateStreetAndRoute(
          address.find((add) => add.field === 'BILLING')?.line1,
        );
        const country =
          address.find((add) => add.field === 'BILLING')?.country ||
          address.find((add) => add.field === 'OTHER')?.country;
        const locality =
          address.find((add) => add.field === 'OTHER')?.locality ||
          address.find((add) => add.field === 'BILLING')?.locality;
        const region =
          address.find((add) => add.field === 'OTHER')?.region ||
          address.find((add) => add.field === 'BILLING')?.region;
        const postal_code = address.find((add) => add.field === 'BILLING')?.postal_code;

        setFieldValue('address', {
          street_number,
          route,
          locality,
          administrative_area_level_1: region,
          postal_code,
          country,
        });
      }
    } else {
      setFieldValue('fname', '');
      setFieldValue('lname', '');
      setFieldValue('phone', '');
      setFieldValue('address', {
        street_number: '',
        route: '',
        locality: '',
        administrative_area_level_1: '',
        postal_code: '',
        country: '',
      });
    }
  };

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    if (fbSearchTimeoutRef.current !== null) {
      clearTimeout(fbSearchTimeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      // perform search operation here
      getContactDetails();
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.email]);

  return (
    <div className='input-field-wrapper'>
      <div className='input-field-wrapper__item'>
        <Input name='email' type='email' label='Email' id='email' placeholder='john@gmail.com' />
      </div>
      <div className='input-field-wrapper__item'>
        <Input label='Phone' type='tel' id='phone' name='phone' placeholder='123-456-7890' />
      </div>
      <div className='input-field-wrapper__item'>
        <Input
          name='fname'
          type='text'
          label='First Name'
          id='fname'
          placeholder='Client first name...'
        />
      </div>
      <div className='input-field-wrapper__item'>
        <Input name='lname' type='text' label='Last Name' placeholder='Client last name..' />
      </div>
      <div className='p-[5px] w-full'>
        <label htmlFor='address' className='text-darkGreen'>
          Address
        </label>
        <br />
        <MapArea setFieldValue={setFieldValue} address={values.address} />
      </div>
      <div className='input-field-wrapper__item'>
        <Input
          name='installationOption'
          type='select'
          options={[
            {
              value: '',
              label: 'Select Installation Type',
            },
            ...installationOptions,
          ]}
          label='Installation Type'
          id='installationOption'
        />
      </div>
      <div className='input-field-wrapper__item'>
        <Input
          name='grass'
          type='select'
          options={[
            {
              value: '',
              label: 'Select Grass Type',
            },
            ...grassOptions,
          ]}
          label='Grass Type'
        />
      </div>
      {values.installationOption === 'playground' && (
        <div className='p-[5px] w-full'>
          <Input
            name='playgroundType'
            type='select'
            options={[
              {
                value: '',
                label: 'Select Playground Type',
              },
              ...playgroundTypes.map((type) => ({ value: type.value, label: type.name })),
            ]}
            label='Current Playground Type'
          />
        </div>
      )}
      <div className='p-[5px] w-full'>
        <Input name='estimateArea' type='number' label='Estimate Area(Sq/ft)' />
      </div>
    </div>
  );
};

export default ManualEstimateForm;
